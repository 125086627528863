import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="px-6 mx-auto max-w-6xl text-center">
      <h1 className="text-3xl mb-6">OOOPS</h1>
      <h2 className="text-xl mb-6">
        It would seem that the naughty dog has out smarted you today <br />
        <span role="img"> 😂😂😂</span>
      </h2>
      <h4 className="text-xl">
        You can go back to the homepage,{" "}
        <a href="/" title="Go Home" className="font-bold">
          Click Here
        </a>
      </h4>
      <img
        src="https://images.ctfassets.net/an9doy248lhu/2sC7HlLs0ZmbPvnDUY6Iz/0df3549350058fcf0346094359595e59/404dog.jpg"
        alt="Your lost 😢"
        className="w-full max-w-3xl mx-auto my-6 rounded-md shadow-light"
      />
    </div>
  </Layout>
)

export default NotFoundPage
